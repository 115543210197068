/* @todo: move to reset file once all button references have been swapped */

.buttonReset {
  /* vertically centers button content; prevents misalignment (particularly of icons) caused by character descenders */
  display: block;
  width: fit-content;

  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
}

.linkReset {
  /* vertically centers button content; prevents misalignment (particularly of icons) caused by character descenders */
  display: block;
  width: fit-content;

  font-size: 16px;
}

/* base class for styled buttons */
.baseStyling {
  font-weight: 500;
}

.button:active {
  filter: brightness(80%);
}

.button:disabled {
  opacity: 0.5;
}

.inline {
  color: var(--foremost-blue);
  text-decoration: underline;
}

.inline.danger {
  color: var(--foremost-danger);
}

.inlineGraySmall {
  color: var(--foremost-gray);
  font-size: 12px;
  text-decoration: underline;
}

.ghostBlue,
.ghostBlueSmall,
.ghostOrange,
.ghostOrangeSmall {
  border-radius: 500px;
  border-style: solid;
  border-width: 2px;
  text-align: center;
}

.ghostBlue, .ghostOrange {
  padding: 13px 23px;
  width: 200px;
}

.ghostBlueSmall, .ghostOrangeSmall {
  font-size: 12px;
  padding: 6px 13px;
  width: 100px;
}

.ghostBlue, .ghostBlueSmall {
  border-color: var(--foremost-blue);
  color: var(--foremost-blue);
}

.ghostOrange, .ghostOrangeSmall {
  border-color: var(--foremost-orange);
  color: var(--foremost-orange);
}

.ghostBlue.danger,
.ghostBlueSmall.danger,
.ghostOrange.danger,
.ghostOrangeSmall.danger {
  border-color: var(--foremost-danger);
  color: var(--foremost-danger);
}

.fillBlue,
.fillBlueSmall,
.fillOrange,
.fillOrangeSmall {
  border-radius: 500px;
  text-align: center;
}

.fillBlue, .fillOrange {
  padding: 15px 25px;
  width: 200px;
}

.fillBlueSmall, .fillOrangeSmall {
  font-size: 12px;
  padding: 8px 15px;
  width: 100px;
}

.fillBlue, .fillBlueSmall {
  background-color: var(--foremost-blue);
  color: #FFF;
}

.fillOrange, .fillOrangeSmall {
  background-color: var(--foremost-orange);
  color: #FFF;
}

.fillBlue.danger,
.fillBlueSmall.danger,
.fillOrange.danger,
.fillOrangeSmall.danger {
  background-color: var(--foremost-danger);
}

.ghostBlue:active,
.ghostBlueSmall:active,
.ghostOrange:active,
.ghostOrangeSmall:active,
.fillBlue:active,
.fillBlueSmall:active,
.fillOrange:active,
.fillOrangeSmall:active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.ghostBlue.contentWidth,
.ghostBlueSmall.contentWidth,
.ghostOrange.contentWidth,
.ghostOrangeSmall.contentWidth,
.fillBlue.contentWidth,
.fillBlueSmall.contentWidth,
.fillOrange.contentWidth,
.fillOrangeSmall.contentWidth {
  width: fit-content;
}

.plain {
  color: var(--foremost-white);
}

@media screen and (max-width: 768px) {
  .inlineGraySmall {
    font-size: 11px;
  }

  .ghostBlue, .ghostOrange {
    font-size: 15px;
    padding: 10px 18px;
    width: 180px;
  }

  .ghostBlueSmall, .ghostOrangeSmall {
    font-size: 11px;
    padding: 5px 11px;
    width: 91px;
  }

  .fillBlue, .fillOrange {
    font-size: 15px;
    padding: 12px 20px;
    width: 180px;
  }

  .fillBlueSmall, .fillOrangeSmall {
    font-size: 11px;
    padding: 7px 13px;
    width: 91px;
  }
}
