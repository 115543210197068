.defaultButtons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 40px;
}

.description {
  color: var(--foremost-gray);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
}

.form {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
}

.form.withBackground {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 var(--foremost-black-faint-4);
  margin-bottom: 150px;
  padding: 30px;
}

.inlineActions {
  display: flex;
  align-items: center;
  column-gap: 50px;
  justify-content: center;
  margin-top: 40px;
}

.pageCount {
  color: var(--foremost-gray);
  font-size: 12px;
  margin-bottom: 10px;
}

.pageMarker {
  background-color: var(--foremost-gray-faint);
  flex-grow: 1;
  height: 4px;
}

.pageMarker.pageMarkerMarked {
  background-color: var(--foremost-blue);
}

.pageTracker {
  column-gap: 4px;
  display: flex;
  margin-bottom: 10px;
}

@media print {
  .form, .form.withBackground {
    border-radius: 0;
    box-shadow: none;
    column-count: 2;
    column-fill: balance;
    column-gap: 50px;
    column-rule: 1px solid var(--foremost-gray-faint);
    margin: 0;
    max-width: 100%;
    padding: 0;
  }
}
