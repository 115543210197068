/* @todo: move to reset file once all button references have been swapped */
.buttonReset {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
}

.option {
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px var(--foremost-gray);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.15);
  color: var(--gray-dark);
  font-size: 14px;
  padding: 10px 20px;
}

.option:active {
  filter: brightness(98%);
}

.option.selected {
  background-color: var(--foremost-white);
  border-color: var(--foremost-blue);
  color: var(--foremost-blue);
}
