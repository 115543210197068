.buttonPanel {
  display: flex;
  column-gap: 15px;
  margin-top: 10px;
}

.errorText {
  color: var(--error-red);
}

.fieldSpacing:not(:last-child) {
  margin-bottom: var(--input-field-spacing);
}

.fileInput {
  /*
    Hide text that browser places next to file button ("No file chosen", name of file, etc).
    This is to prevent confusion when an upload is aborted (due to invalid file type), where the
    user may believe that displaying the file name indicates the file was uploaded (when it was not).
  */
  color: transparent;
}

.groupFields.readonlyColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}

.groupFields.readonlyColumns.columns3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.groupHeader {
  display: block;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  text-decoration: underline;
}

/* adding margin spacing to top AND bottom of groups (rather than bottom-only) to give spacing between groups and non-grouped fields */
.groupWrapper.groupSpacing:not(:first-child) {
  margin-top: 40px;
}

.groupWrapper.groupSpacing:not(:last-child) {
  margin-bottom: 40px;
}

.groupWrapper.groupWrapperFrame {
  background: var(--foremost-blue-faint-1);
  border: 1px solid var(--foremost-blue-faint-2);
  border-radius: 8px;
  padding: 20px;
}

.hiddenInput {
  display: none;
}

.image {
  margin-bottom: 20px;
  max-width: 100%;
}

.input {
  font-size: 14px;
  line-height: 1.4;
  padding: 9px 15px;
  border-radius: 8px;
  border: 1px solid var(--foremost-gray);
  background-color: #fff;
}

.input:focus {
  outline: none;
  border-color: var(--emperor);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.input.date {
  cursor: pointer;
}

.input.dateTime {
  cursor: pointer;
}

.input.dropdown {
  cursor: pointer;
  /* max-width prevents possible overflow if options cause dropdown to be excessively wide */
  max-width: 100%;

  /* setting up custom dropdown arrow; dropdown arrow uses "emperor" color */
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1 7 7 1 1' stroke='%23555' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  padding-right: 65px;
}

.input.textAlphabetical {
  width: 100%;
}

.input.textarea {
  height: 100px;
  width: 100%;
}

.label {
  display: block;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 8px;
}

.lateralFields {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
  row-gap: 5px;
}

.optionGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 50px;
  align-items: center;
}

.readonlyLabel {
  text-decoration: underline;
}

.repeatableGroups {
  display: flex;
  flex-direction: column;
  row-gap: var(--input-field-spacing);
}

.repeatableGroupsSpacing:not(:last-child) {
  margin-bottom: var(--input-field-spacing);
}

.subLabel {
  font-size: 12px;
  margin-bottom: 12px;
}

.upload {
  display: flex;
  align-items: center;
  column-gap: 30px;
  justify-content: space-between;
}

.uploadIndicator {
  color: var(--foremost-blue);
  margin-top: 10px;
}

.uploadsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .input.search {
    max-width: 140px;
  }

  .optionGroup {
    column-gap: 30px;
  }
}

@media print {
  .label {
    font-size: 11px;
    padding-bottom: 5px;
  }

  .readonlyValue {
    font-size: 13px;
  }
}
