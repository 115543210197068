.actions {
  align-items: center;
  column-gap: 50px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.container {
  max-width: 600px;
}

@media screen and (max-width: 768px) {
  .actions {
    column-gap: 40px;
  }
}
