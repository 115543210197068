.background {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.background.stackLevel110 {
  z-index: 110;
}

.background.stackLevel120 {
  z-index: 120;
}

.background.stackLevel130 {
  z-index: 130;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.modal {
  background-color: #FFF;
  border-radius: 10px;
  max-height: 80vh;
  max-width: 80vw;
  overflow: auto;
  padding: 25px;
}

.modal.medium {
  min-height: 60vh;
  min-width: 60vw;
}

@media screen and (max-width: 768px) {
  .modal.medium {
    min-height: 80vh;
    min-width: 80vw;
  }
}
