.buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.canvas {
  display: block;

  /* avoid single-finger scroll on touchscreen */
  touch-action: none;

  /* avoid element highlight from long press on touchscreen */
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  /* width and height are designed to fill out container */
  width: 100%;
  height: 100%;
}

.canvasWrapper {
  aspect-ratio: 3 / 1;
  border: 1px solid black;
}
