*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

input,
button,
select,
textarea {
  color: inherit;
  font-family: inherit;
}

img, svg {
  display: block;
}
